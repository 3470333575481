export const es = {
  menu: {
    home: "inicio",
    editor: "editor",
    models: "Modelos",
    registration: "registro",
    login: "Acceso",
    myModels: "Mis modelos",
    logout: "cerrar sesión",
    myAccount: "Mi cuenta",
    changePhoto: "Cambiar foto",
    changePassword: "Cambia la contraseña",
    evolution: "evolución"
  },

  home: {
    description: "Software de modelado de ecosistemas",
    about: "Sobre",
    models: "Modelos",
    howToCite: "¿Cómo citar esta herramienta?",
    availableIn: "Disponible en",
  },

  account: {
    titleLogin: "Acceso",
    email: "Correo electrónico",
    password: "Clave",
    placeholderEmail: "Introduce tu correo electrónico",
    placeholderPassword: "Ingresa tu contraseña",
    buttonLogin: "Acceso",
    titlePassordChange: "Cambiar mi contraseña",
    oldPassword: "contraseña actual",
    newPassword: "Nueva contraseña",
    repeatNewPassword: "Confirma tu nueva contraseña",
    confirmPassword: "Confirmar la contraseña",
    saveUpdates: "Guardar actualizaciones",
    titleProfile: "Actualizar mis datos",
    name: "Nombre",
    placeholderName: "INTRODUZCA SU NOMBRE",
    institution: "Institución",
    placeholderInstitution: "Ingrese su institución",
    save: "Ahorrar",
    titlePhoto: "Cambiar foto de perfil",
  },

  model: {
    models: "Modelos",
    myModels: "Mis modelos",
    newModel: "Nuevo modelo",
    openInEditor: "Abrir con el editor",
    seeDetails: "Detalles",
    autor: "Autor",
    title: "Título",
    description: "Descripción",
    createAt: "Creado en",
    updateAt: "Última actualización",
    update: "Actualizar",
    delete: "Borrar",
    deleteConfirm: "¿Realmente quieres eliminar el modelo $ {0}?",
    yes: "Sí",
    no: "No",
  },

  messages: {
    loginInvalid: 'Autenticación vencida',
    update: 'Actualizado exitosamente',
    updateErro: 'Error de actualización',
    delete: 'Eliminado exitoso',
    deleteErro: 'Error al eliminar',
    loadErro: 'Error a cargar los modelos',
    bibtex: 'Bibtex copiado',
    welcome: 'Bienvenido',
    error: 'Un error desconocido a ocurrido',
    allFields: 'Complete todos los campos',
    create: 'Modelo guardado con éxito',
    createErro: 'Se produjo un error guardando el modelo',
    oneLoadErro: 'Modelo no encontrado',
    password: 'Las contraseñas deben ser iguales',
    updatePassword: 'Contraseña actualizada exitosa',
  },

  company_of_interest: "Compañía de interés",
  supplier: "Proveedor",
  customer: "Cliente",
  intermediary: "Intermediario",
  customer_customer: "Cliente de cliente",
  aggregator: "Agregador",
  business_relationship: "Relación comercial",
  or: "O",
  xor: "Xou",
  text: "Texto",
  save: "Ahorrar",
  export: "Exportar",
  zoomin: "Zoom más",
  zoomout: "Zoom menos",
  zoomDefault: "Zoom estándar",
  recort: "Separar",
  copy: "Copiar",
  colar: "Collar",
  desfazer: "Deshacer",
  refazer: "Rehacer",
  all: "Seleccionar todo",
  desfazer_selecao: "Selección de Undel",
  print: "Imprimir",
  visualizar: "Para ver",
  propriedades: "Propiedades XML",
  estatisticas: "Estadísticas del modelo",
  relacionamentos: "Relaciones",
  agrupar: "Un grupo",
  desagrupar: "En desacuerdo",
  tras: "Para atrás",
  frente: "Adelante",
  negrito: "Audaz",
  sublinhado: "Subrayada",
  italico: "Itálico",
  export_png: "Exportar en PNG",
  export_svg: "Exportar en SVG",
  export_xml: "Exportar en XML",
  export_json: "Exportación en JSON",
  import: "Importar xml",
  import2: "Importar archivo XML",
  cancel: "Cancelar",
  importar: "Importar",
  baixar: "Descargar archivo",
  imagem_PNG: "Descargar imagen PNG",
  imagem_SVG: "Descargar imagen en SVG",
  xml: "Descargar en XML",
  json: "Descargar en json",

  routes: {
    home: "/es/",
    editor: "/es/editor",
    publicModels: "/es/modelos",
    privateModels: "/es/modelos/privado",
    register: "/es/cadastro",
    login: "/es/login",
    user: "/es/conta",
    photo: "/es/conta/foto",
    password: "/es/conta/password",
    modelEditor: "/es/editor/:codigo",
    evolution: "/es/modelos/evolucao",
  },
};
