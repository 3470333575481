export const pt_br = {
  menu: {
    home: "início",
    editor: "editor",
    models: "modelos",
    registration: "cadastro",
    login: "entrar",
    myModels: "meus modelos",
    logout: "sair",
    myAccount: "minha conta",
    changePhoto: "alterar foto",
    changePassword: "alterar senha",
    evolution: "evolução",
  },

  home: {
    description: "Modelagem de ecossistemas de software",
    about: "Sobre",
    models: "Modelos",
    howToCite: "Como citar essa Ferramenta?",
    availableIn: "Disponível em",
  },

  account: {
    titleLogin: "Realizar Login ",
    email: "Email",
    password: "Senha",
    placeholderEmail: "Insira seu email",
    placeholderPassword: "Insira sua senha",
    buttonLogin: "Entrar",
    titlePassordChange: "Alterar minha senha",
    oldPassword: "Senha atual",
    newPassword: "Nova senha",
    repeatNewPassword: "Confirme sua nova senha",
    confirmPassword: "Confirme sua senha",
    saveUpdates: "Salvar atualizações",
    titleProfile: "Atualizar meus dados",
    name: "Nome",
    placeholderName: "Insira seu nome",
    institution: "Instituição",
    placeholderInstitution: "Insira sua instituição",
    save: "Salvar",
    titlePhoto: "Alterar foto do perfil",
  },

  model: {
    models: "Modelos",
    myModels: "Meus Modelos",
    newModel: "Novo modelo",
    openInEditor: "Abrir no editor",
    seeDetails: "Detalhes",
    autor: "Autor",
    title: "Titulo",
    description: "Descrição",
    createAt: "Criado em",
    updateAt: "Ultima atualização",
    update: "Atualizar",
    delete: "Apagar",
    deleteConfirm: "Deseja realmente excluir o modelo ${0} ?",
    yes: "Sim",
    no: "Não",
    dateFormat: "DD/MM/YYYY",
  },

  messages: {
    loginInvalid: 'Autenticação expirada',
    update: 'Atualizado com sucesso',
    updateErro: 'Erro na Atualização',
    delete: 'Deletado com sucesso',
    deleteErro: 'Erro ao deletar',
    loadErro: 'Erro ao carregar os modelos',
    bibtex: 'BibTex copiado',
    welcome: 'Bem vindo',
    error: 'Ocorreu um erro desconhecido',
    allFields: 'Preencha todos os campos',
    create: 'Modelo salvo com sucesso',
    createErro: 'Ocorreu um erro ao salvar o modelo',
    oneLoadErro: 'Modelo não encontrado',
    password: 'As senhas devem ser iguais',
    updatePassword: 'Senha atualizada com sucesso',
  },

  company_of_interest: "Empresa de Interesse",
  supplier: "Fornecedor",
  customer: "Cliente",
  intermediary: "Intermediário",
  customer_customer: "Cliente do Cliente",
  aggregator: "Agregador",
  business_relationship: "Relação comercial",
  or: "OU",
  xor: "XOU",
  text: "Texto",
  save: "Salvar",
  export: "Exportar",
  zoomin: "Zoom mais",
  zoomout: "Zoom menos",
  zoomDefault: "Zoom padrão",
  recort: "Recortar",
  copy: "Copiar",
  colar: "Colar",
  desfazer: "Desfazer",
  refazer: "Refazer",
  all: "Selecionar Tudo",
  desfazer_selecao: "Desfazer Seleção",
  print: "Imprimir",
  visualizar: "Visualizar",
  propriedades: "Propriedades XML",
  estatisticas: "Estatísticas do modelo",
  relacionamentos: "Relacionamentos",
  agrupar: "Agrupar",
  desagrupar: "Desagrupar",
  tras: "Para Trás",
  frente: "Para Frente",
  negrito: "Negrito",
  sublinhado: "Sublinhado",
  italico: "Itálico",
  export_png: "Exportar em PNG",
  export_svg: "Exportar em SVG",
  export_xml: "Exportar em XML",
  export_json: "Exportar em JSON",
  import: "Importar XML",
  import2: "Importar Arquivo XML",
  cancel: "Cancelar",
  importar: "Importar",
  baixar: "Baixar Arquivo",
  imagem_PNG: "Baixar Imagem em PNG",
  imagem_SVG: "Baixar Imagem em SVG",
  xml: "Baixar em XML",
  json: "Baixar em JSON",

  routes: {
    home: "/pt-br/home",
    editor: "/pt-br/editor",
    publicModels: "/pt-br/modelos",
    privateModels: "/pt-br/modelos/privado",
    register: "/pt-br/cadastro",
    login: "/pt-br/login",
    user: "/pt-br/conta",
    photo: "/pt-br/conta/foto",
    password: "/pt-br/conta/password",
    modelEditor: "/pt-br/editor/:codigo",
    evolution: "/pt-br/modelos/evolucao",
  },
};
