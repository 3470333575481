var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainPage',[_c('section',{staticClass:"content",attrs:{"id":"modelos"}},[(_vm.modelosChecked.length > 1 && !_vm.evolucaoGerada)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.gerar}},[_vm._v(" Gerar relatório de evolução ")]):(_vm.evolucaoGerada)?_c('v-btn',{attrs:{"fluid":"","color":"error"},on:{"click":_vm.limpar}},[_vm._v(" Voltar ")]):_c('h1',[_vm._v("Relatório de evolução de ECOS")]),(!_vm.evolucaoGerada)?_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.pageSize},on:{"input":_vm.pageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),(!_vm.evolucaoGerada)?_c('p',[_vm._v("Selecione pelo menos 2 modelos para gerar evolução")]):_vm._e(),_c('div',{staticClass:"container"},[(_vm.evolucaoGerada && _vm.modelosFinal.length)?_c('div',[_c('v-expansion-panels',{attrs:{"multiple":""}},[_vm._l((_vm.modelosFinal),function(modelo,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('h2',[_vm._v(_vm._s(index + 1 + " - " + modelo.titulo))])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"evolucao-modelo-imagem"},[_c('img',{attrs:{"src":_vm.getPreview(modelo.codigo),"alt":"preview"}})]),_c('div',{staticClass:"evolucao-modelo-tabela"},[_c('v-data-table',{key:modelo.codigo,staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":[{ text: modelo.data[0][0], value: 'name' }, { text: modelo.data[0][1], value: 'total' }],"items":modelo.data.map(function (m, i) { return i != 0 ? ({ name: m[0], total: m[1] }) : null; }).filter(function (v) { return v; })},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"footer",fn:function(){return [_c('v-alert',{attrs:{"outlined":"","type":"info","prominent":"","border":"left"}},[_vm._v(" Total de "+_vm._s(modelo.total)+" componentes ")])]},proxy:true}],null,true)})],1),_c('div',{staticClass:"evolucao-modelo-chart"},[_c('BarChart',{attrs:{"options":Object.assign({}, _vm.options, {title: modelo.titulo + ' - Gráfico Numérico'}),"type":_vm.type,"data":modelo.data}})],1),_c('div',{staticClass:"evolucao-modelo-chart"},[_c('BarChart',{attrs:{"options":Object.assign({}, _vm.options, {title: modelo.titulo + ' - Gráfico Percentual'}),"type":_vm.type,"data":modelo.percent}})],1)])],1)}),_c('v-expansion-panel',{key:"serial1"},[_c('v-expansion-panel-header',[_c('h2',[_vm._v(_vm._s(((this.modelosFinal.length + 1) + " - Comparação entre as versões dos modelos do ECOS")))])]),_c('v-expansion-panel-content',[_c('BarChart',{attrs:{"options":Object.assign({}, {
                                    chart: {
                                        title: 'Comparação entre modelos',
                                    },
                            
                                    height: 600,
                                }, {title: 'Comparação numérica entre os modelos'}),"type":_vm.type,"data":_vm.modelosFinalComparacao,"multiple":true}}),_c('BarChart',{attrs:{"options":Object.assign({}, {
                                    chart: {
                                        title: 'Comparação percentual entre modelos %',
                                    },
                            
                                    height: 600,
                                }, {title: 'Comparação percentual entre os modelos'}),"type":_vm.type,"data":_vm.modelosFinalCompararPercent}}),_c('BarChart',{attrs:{"options":Object.assign({}, {
                            
                                    height: 600,
                                }, {title: 'Quantidade de Componentes e Relacionamentos'}),"type":_vm.type,"data":_vm.compararComponentes,"multiple":true}}),_c('BarChart',{attrs:{"options":Object.assign({}, {
                            
                                    height: 600,
                                }, {title: 'Quantidade apenas de Relacionamentos'}),"type":_vm.type,"data":_vm.compararRelacionamentos,"multiple":true}}),_c('BarChart',{attrs:{"options":Object.assign({}, {
                            
                                    height: 600,
                                }, {title: 'Diferença numérica'}),"type":_vm.type,"data":_vm.diferenca(_vm.modelosFinal[0], _vm.modelosFinal[_vm.modelosFinal.length - 1])}}),_c('BarChart',{attrs:{"options":Object.assign({}, {
                            
                                    height: 600,
                                }, {title: 'Variação Percentual'}),"type":_vm.type,"data":_vm.diferencaPercentual(_vm.modelosFinal[0], _vm.modelosFinal[_vm.modelosFinal.length - 1])}})],1),_c('v-expansion-panel-content',[_vm._l((_vm.gerarComparacaoEndToEnd(_vm.modelosFinal)),function(ref){
                            var modelo = ref.numerico;
                            var modeloP = ref.percentual;
                            var media = ref.media;
                            var name = ref.name;
return _c('div',{key:modelo.codigo},[_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"color":"red","label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" "+_vm._s(name)+" ")],1),_c('v-data-table',{staticClass:"elevation-1 mb-7",attrs:{"hide-default-footer":"","headers":[{ text: modelo[0][0], value: 'name' }, { text: 'Diferença Numérica', value: 'total' }, { text: 'Diferença Percentual', value: 'percentual' }, { text: 'Média', value: 'media' }],"items":modelo.map(function (m, i) { return i != 0 ? ({ name: m[0], total: m[1], percentual: modeloP[i][2], media: media[i][1] }) : null; }).filter(function (v) { return v; })},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"item.percentual",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.percentual.replace('Infinity', '0.00'))+" ")])]}},{key:"item.media",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.media)+" ")])]}}],null,true)})],1)}),_c('v-chip',{staticClass:"ma-2 ml-0",attrs:{"color":"red","label":"","text-color":"white"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-label ")]),_vm._v(" Métricas Quantitativas ")],1),_c('v-data-table',{staticClass:"elevation-1 mb-7",attrs:{"hide-default-footer":"","headers":[{ text: "Métrica", value: 'title' }, { text: "Total", value: 'valor' }],"items":_vm.metricasQuantitativas},scopedSlots:_vm._u([{key:"item.valor",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(item.valor)+" ")])]}}],null,false,2060941601)})],2)],1)],2)],1):_c('v-item-group',[_c('v-container',[_c('v-row',{attrs:{"alignIte":"center"}},_vm._l((_vm.modelos),function(modelo){return _c('v-col',{key:modelo.codigo},[_c('v-card',{staticClass:"mx-auto d-flex flex-column align-center",attrs:{"max-width":"350","min-width":"350"}},[_c('v-checkbox',{attrs:{"value":modelo.codigo},model:{value:(_vm.modelosChecked),callback:function ($$v) {_vm.modelosChecked=$$v},expression:"modelosChecked"}}),_c('v-item',[_c('CardModelo',{attrs:{"modelo":modelo,"canEdit":false,"refresh":_vm.getModelos}})],1),_c('v-checkbox',{attrs:{"value":modelo.codigo},model:{value:(_vm.modelosChecked),callback:function ($$v) {_vm.modelosChecked=$$v},expression:"modelosChecked"}})],1)],1)}),1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }