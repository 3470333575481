export const en = {
  menu: {
    home: "home",
    editor: "editor",
    models: "models",
    registration: "registration",
    login: "log in",
    myModels: "my models",
    logout: "logout",
    myAccount: "my account",
    changePhoto: "Change photo",
    changePassword: "Change Password",
    evolution: "evolution",
  },

  home: {
    description: "Software Ecosystems Modeling",
    about: "About",
    models: "Models",
    howToCite: "How to cite this tool?",
    availableIn: "Available in",
  },

  account: {
    titleLogin: "Login",
    email: "Email",
    password: "Password",
    placeholderEmail: "Enter your email",
    placeholderPassword: "Enter your password",
    buttonLogin: "Log in",
    titlePassordChange: "Change my password",
    oldPassword: "current password",
    newPassword: "New Password",
    repeatNewPassword: "Confirm your new password",
    confirmPassword: "Confirm your password",
    saveUpdates: "Save updates",
    titleProfile: "Update my data",
    name: "Name",
    placeholderName: "Enter your name",
    institution: "Institution",
    placeholderInstitution: "Enter your institution",
    save: "To save",
    titlePhoto: "Change profile photo",
  },

  model: {
    models: "Models",
    myModels: "My models",
    newModel: "New model",
    openInEditor: "Open in editor",
    seeDetails: "Details",
    autor: "Author",
    title: "Title",
    description: "Description",
    createAt: "Created in",
    updateAt: "Last update",
    update: "Update",
    delete: "Delete",
    deleteConfirm: "Do you really want to delete the $ {0} model?",
    yes: "Yea",
    no: "No",
  },

  messages: {
    loginInvalid: 'Expired authentication',
    update: 'Successfully updated',
    updateErro: 'Update Error',
    delete: 'Successful deleted',
    deleteErro: 'Error when deleting',
    loadErro: 'Error loading the models',
    bibtex: 'Bibtex copied',
    welcome: 'Welcome',
    error: 'An unknown error has occurred',
    allFields: 'Fill in all fields',
    create: 'Model Saved successfully',
    createErro: 'An error occurred by saving the model',
    oneLoadErro: 'Model not found',
    password: 'Passwords must be equal',
    updatePassword: 'Successful updated password',
  },

  company_of_interest: "Company of interest",
  supplier: "Provider",
  customer: "Client",
  intermediary: "Intermediary",
  customer_customer: "Customer customer",
  aggregator: "Aggregator",
  business_relationship: "Commercial relationship",
  or: "OR",
  xor: "Xou",
  text: "Text",
  save: "To save",
  export: "Export",
  zoomin: "Zoom More",
  zoomout: "Zoom less",
  zoomDefault: "Standard Zoom",
  recort: "Cut out",
  copy: "Copy",
  colar: "Necklace",
  desfazer: "Undo",
  refazer: "Redo",
  all: "Select all",
  desfazer_selecao: "Undel selection",
  print: "Print out",
  visualizar: "To view",
  propriedades: "XML Properties",
  estatisticas: "Model Statistics",
  relacionamentos: "Relationships",
  agrupar: "Agroup",
  desagrupar: "Disagreeing",
  tras: "Back",
  frente: "Forward",
  negrito: "Bold",
  sublinhado: "Underlined",
  italico: "Italic",
  export_png: "Export in PNG",
  export_svg: "Export in SVG",
  export_xml: "Export in XML",
  export_json: "Export in JSON",
  import: "Import XML",
  import2: "Import XML File",
  cancel: "Cancel",
  importar: "Import",
  baixar: "Download file",
  imagem_PNG: "Download PNG Image",
  imagem_SVG: "Download Image in SVG",
  xml: "Download in XML",
  json: "Download in Json",

  routes: {
    home: "/en/",
    editor: "/en/editor",
    publicModels: "/en/modelos",
    privateModels: "/en/modelos/privado",
    register: "/en/cadastro",
    login: "/en/login",
    user: "/en/conta",
    photo: "/en/conta/foto",
    password: "/en/conta/password",
    modelEditor: "/en/editor/:codigo",
    evolution: "/en/modelos/evolucao",
  },
};
